exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-demo-cases-index-js": () => import("./../../../src/pages/demo-cases/index.js" /* webpackChunkName: "component---src-pages-demo-cases-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-make-audit-index-js": () => import("./../../../src/pages/make-audit/index.js" /* webpackChunkName: "component---src-pages-make-audit-index-js" */),
  "component---src-pages-policy-index-js": () => import("./../../../src/pages/policy/index.js" /* webpackChunkName: "component---src-pages-policy-index-js" */),
  "component---src-pages-projects-acellus-health-index-js": () => import("./../../../src/pages/projects/acellus-health/index.js" /* webpackChunkName: "component---src-pages-projects-acellus-health-index-js" */),
  "component---src-pages-projects-astics-index-js": () => import("./../../../src/pages/projects/astics/index.js" /* webpackChunkName: "component---src-pages-projects-astics-index-js" */),
  "component---src-pages-projects-beam-index-js": () => import("./../../../src/pages/projects/beam/index.js" /* webpackChunkName: "component---src-pages-projects-beam-index-js" */),
  "component---src-pages-projects-change-plan-index-js": () => import("./../../../src/pages/projects/change-plan/index.js" /* webpackChunkName: "component---src-pages-projects-change-plan-index-js" */),
  "component---src-pages-projects-combinvest-index-js": () => import("./../../../src/pages/projects/combinvest/index.js" /* webpackChunkName: "component---src-pages-projects-combinvest-index-js" */),
  "component---src-pages-projects-construction-index-js": () => import("./../../../src/pages/projects/construction/index.js" /* webpackChunkName: "component---src-pages-projects-construction-index-js" */),
  "component---src-pages-projects-coworking-booking-platform-index-js": () => import("./../../../src/pages/projects/coworking-booking-platform/index.js" /* webpackChunkName: "component---src-pages-projects-coworking-booking-platform-index-js" */),
  "component---src-pages-projects-developers-corporate-platform-index-js": () => import("./../../../src/pages/projects/developers-corporate-platform/index.js" /* webpackChunkName: "component---src-pages-projects-developers-corporate-platform-index-js" */),
  "component---src-pages-projects-emergency-service-index-js": () => import("./../../../src/pages/projects/emergency-service/index.js" /* webpackChunkName: "component---src-pages-projects-emergency-service-index-js" */),
  "component---src-pages-projects-feed-4-function-index-js": () => import("./../../../src/pages/projects/feed-4-function/index.js" /* webpackChunkName: "component---src-pages-projects-feed-4-function-index-js" */),
  "component---src-pages-projects-filara-cosmo-index-js": () => import("./../../../src/pages/projects/filara-cosmo/index.js" /* webpackChunkName: "component---src-pages-projects-filara-cosmo-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-ingrad-index-js": () => import("./../../../src/pages/projects/ingrad/index.js" /* webpackChunkName: "component---src-pages-projects-ingrad-index-js" */),
  "component---src-pages-projects-juniqe-index-js": () => import("./../../../src/pages/projects/juniqe/index.js" /* webpackChunkName: "component---src-pages-projects-juniqe-index-js" */),
  "component---src-pages-projects-kubevious-index-js": () => import("./../../../src/pages/projects/kubevious/index.js" /* webpackChunkName: "component---src-pages-projects-kubevious-index-js" */),
  "component---src-pages-projects-labclick-index-js": () => import("./../../../src/pages/projects/labclick/index.js" /* webpackChunkName: "component---src-pages-projects-labclick-index-js" */),
  "component---src-pages-projects-omnivati-index-js": () => import("./../../../src/pages/projects/omnivati/index.js" /* webpackChunkName: "component---src-pages-projects-omnivati-index-js" */),
  "component---src-pages-projects-otterfish-index-js": () => import("./../../../src/pages/projects/otterfish/index.js" /* webpackChunkName: "component---src-pages-projects-otterfish-index-js" */),
  "component---src-pages-projects-scompler-index-js": () => import("./../../../src/pages/projects/scompler/index.js" /* webpackChunkName: "component---src-pages-projects-scompler-index-js" */),
  "component---src-pages-projects-shping-index-js": () => import("./../../../src/pages/projects/shping/index.js" /* webpackChunkName: "component---src-pages-projects-shping-index-js" */),
  "component---src-pages-projects-uprise-index-js": () => import("./../../../src/pages/projects/uprise/index.js" /* webpackChunkName: "component---src-pages-projects-uprise-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-services-electronic-document-management-index-js": () => import("./../../../src/pages/services/electronic-document-management/index.js" /* webpackChunkName: "component---src-pages-services-electronic-document-management-index-js" */),
  "component---src-pages-services-implementation-ai-index-js": () => import("./../../../src/pages/services/implementation-ai/index.js" /* webpackChunkName: "component---src-pages-services-implementation-ai-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-outstaffing-index-js": () => import("./../../../src/pages/services/outstaffing/index.js" /* webpackChunkName: "component---src-pages-services-outstaffing-index-js" */),
  "component---src-pages-services-parser-development-index-js": () => import("./../../../src/pages/services/parser-development/index.js" /* webpackChunkName: "component---src-pages-services-parser-development-index-js" */),
  "component---src-pages-services-razrabotka-internet-magazina-index-js": () => import("./../../../src/pages/services/razrabotka-internet-magazina/index.js" /* webpackChunkName: "component---src-pages-services-razrabotka-internet-magazina-index-js" */),
  "component---src-pages-services-razrabotka-lendingov-index-js": () => import("./../../../src/pages/services/razrabotka-lendingov/index.js" /* webpackChunkName: "component---src-pages-services-razrabotka-lendingov-index-js" */),
  "component---src-pages-services-razrabotka-mobilnykh-prilozhenij-index-js": () => import("./../../../src/pages/services/razrabotka-mobilnykh-prilozhenij/index.js" /* webpackChunkName: "component---src-pages-services-razrabotka-mobilnykh-prilozhenij-index-js" */),
  "component---src-pages-services-razrabotka-sajtov-index-js": () => import("./../../../src/pages/services/razrabotka-sajtov/index.js" /* webpackChunkName: "component---src-pages-services-razrabotka-sajtov-index-js" */),
  "component---src-pages-services-razrabotka-web-prilozhenij-index-js": () => import("./../../../src/pages/services/razrabotka-web-prilozhenij/index.js" /* webpackChunkName: "component---src-pages-services-razrabotka-web-prilozhenij-index-js" */),
  "component---src-pages-services-site-redesign-index-js": () => import("./../../../src/pages/services/site-redesign/index.js" /* webpackChunkName: "component---src-pages-services-site-redesign-index-js" */),
  "component---src-pages-services-web-design-index-js": () => import("./../../../src/pages/services/web-design/index.js" /* webpackChunkName: "component---src-pages-services-web-design-index-js" */),
  "component---src-pages-vacancies-index-js": () => import("./../../../src/pages/vacancies/index.js" /* webpackChunkName: "component---src-pages-vacancies-index-js" */),
  "component---src-templates-cms-service-js": () => import("./../../../src/templates/cmsService.js" /* webpackChunkName: "component---src-templates-cms-service-js" */),
  "component---src-templates-outstaff-service-js": () => import("./../../../src/templates/outstaffService.js" /* webpackChunkName: "component---src-templates-outstaff-service-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-vacancy-js": () => import("./../../../src/templates/vacancy.js" /* webpackChunkName: "component---src-templates-vacancy-js" */)
}

